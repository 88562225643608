import { put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './slices'
import { getBookingListReq, pushNotificationContactReq, pushNotificationNotActualReq, removeBookingReq } from '../../../api/booking'

export function* getBookingListApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { page, size } } = action
  try {
    const { data } = yield getBookingListReq(page, size)
    yield put(actions.setBookingList(data.bookings))
    yield put(actions.setBookingsTotal(data.totalCount))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* appendBookingListApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { page, size } } = action
  const bookingList = yield select(state => state.pages.bookings.bookingList)
  try {
    const { data } = yield getBookingListReq(page, size)
    yield put(actions.setBookingsTotal(data.totalCount))
    const resultBookings = [...bookingList, ...data.bookings]
    yield put(actions.setBookingList(resultBookings))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* removeBookingApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { bookingId } } = action
  try {
    yield removeBookingReq(bookingId)
    yield put(actions.getBookingList({ page: 0, size: 50 }))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* sendNotificationContactApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { bookingId } } = action
  try {
    yield pushNotificationContactReq(bookingId)
    yield put(actions.getBookingList({ page: 0, size: 50 }))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* sendNotificationNotActualApi(action) {
  yield put(actions.setIsLoading(true))
  const { payload: { bookingId } } = action
  try {
    yield pushNotificationNotActualReq(bookingId)
    yield put(actions.getBookingList({ page: 0, size: 50 }))
  } catch (error) {
    console.error(error)
  }
  yield put(actions.setIsLoading(false))
}

export function* watchBookingsApi() {
  yield takeLatest(actions.getBookingList.type, getBookingListApi)
  yield takeLatest(actions.appendBookingList.type, appendBookingListApi)
  yield takeLatest(actions.removeBooking.type, removeBookingApi)
  yield takeLatest(actions.sendNotificationContact.type, sendNotificationContactApi)
  yield takeLatest(actions.sendNotificationNotActual.type, sendNotificationNotActualApi)
}
