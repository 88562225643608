import { createAction, createSlice, PayloadAction, } from '@reduxjs/toolkit'

const prefix = 'pages/bookings'

export const clearStore = createAction(`${prefix}/clearStore`)

const bookingsSlice = createSlice({
  name: prefix,
  initialState: {
    isLoading: false as boolean,
    bookingList: [] as any,
    bookingsTotal: 0
  },
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setBookingList: (state, { payload }: PayloadAction<any>) => {
      state.bookingList = payload
    },
    setBookingsTotal: (state, { payload }: PayloadAction<any>) => {
      state.bookingsTotal = payload
    }
  },
  extraReducers: {
    [clearStore.type]: () => ({
      isLoading: false,
      bookingList: [],
      bookingsTotal: 0
    }),
  },
})

// actions
export const { setIsLoading, setBookingList, setBookingsTotal } = bookingsSlice.actions

export const getBookingList = createAction<{ page: number, size: number }>(`${prefix}/getBookingList`)
export const appendBookingList = createAction<{ page: number, size: number }>(`${prefix}/appendBookingList`)
export const removeBooking = createAction<{ bookingId: string }>(`${prefix}/removeBooking`)
export const sendNotificationContact = createAction<{ bookingId: string }>(`${prefix}/sendNotificationContact`)
export const sendNotificationNotActual = createAction<{ bookingId: string }>(`${prefix}/sendNotificationNotActual`)

export const bookingsReducer = bookingsSlice.reducer
